import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import favicon from '../images/icon.png'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './index.css'
import Footer from './Footer'

export default function Layout(props) {
  const [cookieBannerDisplayed, setCookieBannerDisplayed] = useState(false)

  function cookieButtonTapped() {
    if (window) {
      window.localStorage.setItem('cookie-banner-displayed', 'true')
    }

    setCookieBannerDisplayed(false)
  }

  useEffect(() => {
    if (window) {
      let item = window.localStorage.getItem('cookie-banner-displayed')

      if (item == undefined || item != 'true') {
        setCookieBannerDisplayed(true)
      }
    }
  })

  return (
    <div>
      {/* <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: 'description', content: 'Meet Clerkie, your reliable money assistant. Clerkie answers your questions, saves you money, manages your budget, and helps you stretch every dollar.' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      /> */}
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Helmet
              titleTemplate={`%s | ${data.site.siteMetadata.title}`}
              defaultTitle={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content:
                    'Meet Clerkie, your reliable debt and money assistant. Clerkie helps you get out of debt and builds your credit by negotiating up to 70% off your debt. Get answers to your money questions, save money, and manage your budget so you can stretch every dollar.',
                },
                {
                  name: 'keywords',
                  content:
                    'debt tracker, debt management, debt free, debt calculator, student loans, debt consolidation, auto loans, debt control, credit card debt, credit union, debt payoff planner, bank fees, build credit, bill organizer',
                },
              ]}
            />
            <div>
              {props.hideHeader && props.hideHeader == true ? null : (
                <Header
                  hideHeader={props.hideHeader}
                  disableScrollAnimation={props.disableScrollAnimation}
                  headerScrolledColor={props.headerScrolledColor}
                  primaryLogo={props.primaryLogo}
                  primaryColor={props.primaryColor}
                  alwaysPrimary={props.alwaysPrimary || false}
                  addImageMargin={props.addImageMargin || false}
                  heroMobileMultiplier={props.heroMobileMultiplier}
                  heroDesktopMultiplier={props.heroDesktopMultiplier}
                  buttons={props.buttons || []}
                  menuButtons={props.menuButtons || []}
                  secondaryButtonPrimaryBackground={
                    props.secondaryButtonPrimaryBackground
                  }
                  secondaryButtonPrimaryColor={
                    props.secondaryButtonPrimaryColor
                  }
                />
              )}
              {props.children}
              {cookieBannerDisplayed == true ? (
                <div className="CookieFooter">
                  <p>
                    This site uses cookies to give you a good user experience.
                    We do not sell or share your data to third parties.
                  </p>
                  <button onClick={cookieButtonTapped}>Ok</button>
                </div>
              ) : null}
              <Footer />
            </div>
          </>
        )}
      />
    </div>
  )
}
