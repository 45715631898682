import React from 'react';
import Link from 'gatsby-link';
import blueLogo from '../images/clerkie-logo.png';
import whiteLogo from '../images/clerkie-logo-white.png';
import COLORS from "../resources/colors"
import Drawer from "../components/Drawer"

const isWindowContext = typeof window !== "undefined";

class Header extends React.Component { 

  constructor(props) { 
    super(props);

    this.state = { 
      hasScrolled: false,
      isMobile: false,
      drawerIsOpen: false
    } 

    this.drawerRef = React.createRef()  
  }

  // componentWillReceiveProps(nextProps){
  //   if (nextProps.params.slug !== this.props.params.slug) {
  //       const {dispatch, params} = nextProps;
  //       PortfolioDetail.readyOnActions(dispatch, params, true);
  //   }
  // }

  componentDidMount() { 
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); 
  }

  componentWillUnmount() { 
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate() { 

  }

  onMainButtonPressed() { 
    if (this.props.onMainButtonPressed != undefined) { 
      this.props.onMainButtonPressed()
    }   
  }

  onSecondaryButtonPressed() { 
    if (this.props.onSecondaryButtonPressed != undefined) { 
      this.props.onSecondaryButtonPressed()
    }   
  }

  handleScroll = () => {

    this.heroMobileMultiplier = this.props.heroMobileMultiplier || 0.54
    this.heroDesktopMultiplier = this.props.heroDesktopMultiplier || 2.0

    const innerWidth = window.innerWidth;
    const isMobile = window.innerWidth <= 640;
    const heroHeight = isMobile === false ? innerWidth * this.heroMobileMultiplier : innerWidth * this.heroDesktopMultiplier;
    const headerHeight = isMobile ? 80 : 40 + (innerWidth * 0.0238);

    const scrollTop = window.pageYOffset;
  
    if (scrollTop > (heroHeight - headerHeight)) { 
      this.setState({ hasScrolled: true } );
    } else { 
      this.setState({ hasScrolled: false } );
    }
  };

  handleResize = () => {

    const innerWidth = window.innerWidth;
    const isMobile = window.innerWidth <= 640;
    
    if (this.state.isMobile != isMobile) { 
        this.setState({ isMobile: isMobile } );
    }
  };

  getIsMobile() { 

    if (isWindowContext == true) { 
      const isMobile = window.innerWidth <= 640;
      return isMobile
    } else { 
      return false
    }
  }

  getPrimaryButtonClassName() { 
    if (this.props.disableScrollAnimation == true) { 
      return ""
    } else { 
      return this.state.hasScrolled || this.props.alwaysPrimary || false ? "ScrolledButton" : "" 
    }
  }

  getPrimaryButton(button) { 
    return <div>
          <button key={button.title} className={this.getPrimaryButtonClassName()} onClick={button.action}>{button.title}</button>
          <style jsx>{`
             
              button { 
              font-size: 13px;
              font-weight: bold;
              color: ${this.props.alwaysPrimary || false ? "white" : "black"};
              text-transform: uppercase;
              background: ${this.props.alwaysPrimary || false ? this.props.primaryColor || COLORS.clerkieColor : "white"};
              padding: 0px 25px;
              height: 40px;
              border: 0px solid rgba(255, 255, 255, 0.8);
              border-radius: 40px;
              float: right;
              /*box-shadow: 0 3px 10px 0 rgba(0,0,0,0.3);*/
              cursor: pointer;
              transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);  
            }

            button:focus {
              outline: none;
            }

            button:active:focus { 
              outline: none;
            }

            .ScrolledButton { 
              background: ${this.props.primaryColor || COLORS.clerkieColor};
              color: white;
            }

            @media (max-width: 640px) { 
              button { 
                font-size: 2.5vw;
                padding: 0px 4.9vw;
                height: 7.93vw;
              }
           }
        `}</style>
      </div>
  }

  getSecondaryButtonClassName() { 
    if (this.props.disableScrollAnimation == true) { 
      return "ScrolledButton"
    } else { 
      return this.state.hasScrolled || this.props.alwaysPrimary || false ? "ScrolledButton" : ""
    }
  }

  getSecondaryButton(button) { 
    return <div>
          <button key={button.title} className={this.getSecondaryButtonClassName()}  onClick={button.action}>{button.title}</button>
          <style jsx>{`
             
              button { 
                font-size: 13px;
                font-weight: bold;
                color: white; 
                text-transform: uppercase;
                background: rgba(0, 0, 0, 0.5);
                padding: 0px 25px;
                height: 40px;
                border: 0px solid rgba(255, 255, 255, 0.8);
                border-radius: 40px;
                float: right;
                /*box-shadow: 0 3px 10px 0 rgba(0,0,0,0.3);*/
                cursor: pointer;
                transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
                display: grid;
                align-items: center;
                margin-right: 10px;
              }

              button:focus {outline:0;}
              .ScrolledButton { 
                background: ${this.props.secondaryButtonPrimaryBackground || "rgba(0.0, 0.00, 0.0, 1.0)"};
                color:  ${this.props.secondaryButtonPrimaryColor || "white"};
              }            
            @media (max-width: 640px) { 
              button { 
                font-size: 2.5vw;
                padding: 0px 4.9vw;
                height: 7.93vw;
            }
           }
        `}</style>
      </div>
  }

  getMenuButtonClassName() { 
    if (this.props.disableScrollAnimation == true) { 
      return "ScrolledButton"
    } else { 
      return this.state.hasScrolled || this.props.alwaysPrimary || false ? "ScrolledButton" : ""
    } 
  }

  getMenuButton(button) { 
    return <div className="Button" onClick={button.action}>
          <p key={button.title} className={this.getMenuButtonClassName()} >{button.title}</p>
          {button.selected === true ? <div className={this.state.hasScrolled || this.props.alwaysPrimary || false ? "ScrolledLine" : "Line" }></div> : null}
          
          <style jsx>{`

              .Button { 
                font-size: 13px;
                font-weight: bold;
                color: white; 
                text-transform: uppercase;
                padding: 0px 25px;
                height: 40px;
                float: right;
                cursor: pointer;
                transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
                display: grid;
                align-items: center;
                margin-right: 0px;
                grid-row: 1;
                margin-right: 10px;
                display: auto;
              }

              .Line { 
                grid-row: 2;
                font-size: 13px;
                font-weight: bold;
                background-color: white; 
                text-transform: uppercase;
                width: 20px;
                height: 6px;
                border-radius: 5px;
                transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
                justify-self: center;
                transform: translate(0, -7.5px);
              }

              .ScrolledLine { 
                grid-row: 2;
                font-size: 13px;
                font-weight: bold;
                background-color: ${this.props.primaryColor || COLORS.clerkieColor}; 
                text-transform: uppercase;
                width: 20px;
                height: 6px;
                border-radius: 5px;
                transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
                justify-self: center;
                transform: translate(0, -7.5px);
              }

            .ScrolledButton { 
              background: rgba(0, 0, 0, 0.0);
              color: ${this.props.primaryColor || COLORS.clerkieColor};
            }

            @media (max-width: 700px) { 
              button { 
                font-size: 2.5vw;
                padding: 0px 4.9vw;
                height: 7.93vw;
                
              }

              .Button { 
                display: none;
              }
           }
        `}</style>
      </div>
  }

  getButton(button, index) {

      let isSecondary = button.isSecondary || false
    
      if (index === 0) { 
        return this.getPrimaryButton(button)
      } else if (isSecondary) {

        let showOnMobile = button.showOnMobile === true || false

        if (showOnMobile === true || this.getIsMobile() === false) { 
          return this.getSecondaryButton(button)
        } else { 
          return null
        }

      } else { 

        let showOnMobile = button.showOnMobile === true || false

        if (showOnMobile === true || this.getIsMobile() === false) { 
          return this.getMenuButton(button)
        } else { 
          return null
        }
      }
  }


  openDrawer = () => { 

    this.setState({ drawerIsOpen: true } );
  }

  getHeaderClassName() { 
    if (this.props.disableScrollAnimation == true) { 
      return "Header HeaderScrolled"
    } else { 
      return this.state.hasScrolled || this.props.alwaysOpaque ? "Header HeaderScrolled" : "Header" 
    } 
  }

  render() { 

    const addImageMargin = this.props.addImageMargin || false
    const buttons = this.props.buttons || []
    const menuButtons = this.props.menuButtons || []
    const primaryLogo = this.props.primaryLogo || blueLogo

    return ( 
      <div>
        <div className={this.getHeaderClassName()}>
        <div className='HeaderGroup'>
          <div className='LeftGroup'>
            <Drawer logoSrc={this.state.hasScrolled || this.props.alwaysPrimary || false ? primaryLogo : whiteLogo} primaryLogo={primaryLogo} isOpen={this.state.drawerIsOpen} addImageMargin={addImageMargin} menuButtons={menuButtons}/>
          </div>
          <div className='RightGroup'>
              {buttons.map((button, index) => {
                  return this.getButton(button, index)
              })}
          </div>
        </div>
      </div>
        <style jsx="true">{`
             .Header { 
              position: fixed;
              width: 100%;
              padding: 1.19vw 0px;
              z-index: 100;
              transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
            }

             .HeaderScrolled { 
              background: ${this.props.headerScrolledColor || "rgba(255, 255, 255, 1.0)"};
              box-shadow: 0 0px 10px 0 rgba(0,0,0,0.2);
            }

             .HeaderGroup { 
              display: grid;
              grid-template-areas: "a b";
              grid-template-columns: 2.97vw auto;
              align-items: center;
              margin: 0px 1.19vw;
              height: 40px;
            }

              .LeftGroup { 
              grid-area: a;
              display: grid;
              grid-template-columns: auto auto;
            }

            .HeaderGroup img { 
              height: 40px;
              transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
              margin-left: ${this.getIsMobile() === false && addImageMargin === true ? "20px" : "0px"};
            }

             .RightGroup { 
              grid-area: b;
            }

            @media (max-width: 640px) { 
               .Header { 
                  position: fixed;
                  width: 100%;
                  padding: 20px 0px;
                  z-index: 100;
              }
              
               .HeaderGroup { 
                  display: grid;
                  grid-template-areas: "a b";
                  grid-template-columns: 50px auto;
                  align-items: center;
                  margin: 0px 20px;
              }

              .HeaderGroup img { 
                margin-left: 0px;
              }
            }

`}</style>
      </div>
      
    )
  }
}

export default Header
