import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import COLORS from '../resources/colors'

export default class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.handleClick = this.handleClick.bind(this)

    this.menuButtons = props.menuButtons || []
  }

  toggleDrawer = open => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    this.setState({ isOpen: open })
  }

  handleClick = item => {}

  getMenuOption(button, index) {
    if (button.type === 'item') {
      return (
        <div className="MenuListItem" onClick={button.action}>
          <h1 className="MenuListItemText">{button.title}</h1>
          <style jsx="true">{`
            .MenuListItem {
              width: 100%;
              height: auto;
              cursor: pointer;
              padding: 0px;
              margin: 0px;
              background-color: ${button.selected === true
                ? '#333'
                : COLORS.clear};
            }

            .MenuListItem:hover {
              background-color: #333;
            }

            .MenuListItemText {
              font-size: 14px;
              padding: 12.5px 20px;
              margin: 0px;
              font-weight: 500;
              color: white;
            }
          `}</style>
        </div>
      )
    } else if (button.type === 'line') {
      return (
        <div className="MenuListItemLine" onClick={button.action}>
          <style jsx="true">{`
            .MenuListItemLine {
              width: 100%;
              height: 1px;
              background-color: ${COLORS.myMagnesiumGrayColor};
              color: white;
            }
          `}</style>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div>
        {
          <React.Fragment key="left">
            <img onClick={this.toggleDrawer(true)} src={this.props.logoSrc} />
            <Drawer
              anchor="left"
              open={this.state.isOpen}
              onClose={this.toggleDrawer(false)}
            >
              <div
                style={{
                  width: '250px',
                  backgroundColor: 'black',
                  height: '100%',
                }}
              >
                <div className="Header">
                  <div className="HeaderGroup">
                    <div className="LeftGroup">
                      <img
                        onClick={this.toggleDrawer(false)}
                        src={this.props.primaryLogo}
                      />
                    </div>
                  </div>
                </div>
                {this.menuButtons.map((button, index) => {
                  return this.getMenuOption(button, index)
                })}
              </div>
            </Drawer>
          </React.Fragment>
        }

        <style jsx="true">{`
          .Header {
            width: 100%;
            padding: 1.19vw 0px;
          }

          .HeaderGroup {
            display: grid;
            grid-template-areas: 'a b c';
            grid-template-columns: 2.97vw auto;
            align-items: center;
            margin: 0px 1.19vw;
            height: 40px;
          }

          .HeaderGroup img {
            height: 40px;
            transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
            margin-left: ${this.state.isMobile === false &&
            this.addImageMargin === true
              ? '20px'
              : '0px'};
          }

          img {
            height: 40px;
            transition: 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
            margin-left: ${this.props.addImageMargin || false === true
              ? '20px'
              : '0px'};
          }

          @media (max-width: 640px) {
            .Header {
              width: 100%;
              padding: 20px 0px;
            }

            .HeaderGroup {
              display: grid;
              grid-template-areas: 'a b c';
              grid-template-columns: 50px auto px;
              align-items: center;
              margin: 0px 20px;
            }

            img {
              margin-left: 0px;
            }
          }
        `}</style>
      </div>
    )
  }
}
